import type { Balance } from './balance_calculator'

export function init (el: HTMLFormElement): PaymentForm {
  const form = new PaymentForm(el)
  form.bind()
  return form
}

class FillGiftButton {
  form: HTMLFormElement
  button: HTMLElement

  constructor (form: HTMLFormElement, button: HTMLElement) {
    this.form = form
    this.button = button
  }

  bind () {
    this.button.addEventListener('click', this.fillScaled)
  }

  markAsSelected () {
    this.button.classList.add('btn-pressed')
  }

  markAsUnselected () {
    this.button.classList.remove('btn-pressed')
  }

  fillScaled = () => {
    this.targetEl.value = String(this.giftAmount)
    this.targetEl.dispatchEvent(new Event('change'))
  }

  get targetEl (): HTMLInputElement {
    return this.form.querySelector<HTMLInputElement>('#js-gift-amount')!
  }

  get sourceEl (): HTMLInputElement {
    return this.form.querySelector<HTMLInputElement>('#js-amount-input')!
  }

  get giftAmount (): number {
    const scale = this.button.dataset.scale
    if (scale === 'all') {
      return this.remainingBalance
    } else {
      return Math.ceil(Number(this.sourceEl.value) * Number(scale))
    }
  }

  get remainingBalance (): number {
    const balance = Number(this.button.dataset.totalBalance)
    const amount = Number(this.sourceEl.value)
    return Math.max(balance - amount, 0)
  }
}

class PaymentForm {
  el: HTMLFormElement
  fillGiftButtons: FillGiftButton[] = []

  constructor (el: HTMLFormElement) {
    this.el = el
  }

  bind () {
    this.bindFillGiftButtons()
    this.bindGiftAmountInput()
    this.bindAmountInput()
    this.bindCalculation()
  }

  bindFillGiftButtons () {
    const buttons = []
    for (const btn of this.el.querySelectorAll<HTMLElement>('.js-fill-gift-button')) {
      const button = new FillGiftButton(this.el, btn)
      button.bind()
      buttons.push(button)
    }

    this.fillGiftButtons = buttons
  }

  bindGiftAmountInput () {
    if (!this.giftAmountInput) { return }

    const el = this.giftAmountInput
    el.addEventListener('change', this.toggleFillGiftButton)
    el.addEventListener('input', this.toggleFillGiftButton)
    this.toggleFillGiftButton()
  }

  bindAmountInput () {
    if (!this.amountInput) { return }

    const el = this.amountInput
    el.addEventListener('input', this.updateGiftAmountAsDefault)
    el.addEventListener('change', this.updateGiftAmountAsDefault)
    el.addEventListener('blur', this.updateGiftAmountAsDefault)
  }

  bindCalculation () {
    this.el.addEventListener('balance:calculated', this.updateBalanceStatus)
  }

  toggleFillGiftButton = () => {
    for (const button of this.fillGiftButtons) {
      button.markAsUnselected()
    }

    const currVal = Number(this.giftAmountInput.value)
    if (currVal === 0) { return }

    for (const button of this.fillGiftButtons) {
      if (currVal === button.giftAmount) {
        button.markAsSelected()
        // NOTE: 1などscaledValueが重複する場合は最初のボタンだけを選んだ状態にする
        break
      }
    }
  }

  updateGiftAmountAsDefault = () => {
    if (!this.giftAmountInput) { return }

    const value = Math.ceil(Number(this.amountInput.value) * 0.1)
    this.giftAmountInput.value = String(value)
    this.giftAmountInput.dispatchEvent(new Event('change'))
  }

  updateBalanceStatus = (event: Event) => {
    if (!this.accountBalanceNotEnoughMessage) { return }

    const ev = event as CustomEvent
    const balance = ev.detail as Balance
    if (balance.money < 0) {
      this.accountBalanceNotEnoughMessage.classList.remove('hidden')
      if (this.accountBalanceNotEnoughTarget) {
        this.accountBalanceNotEnoughTarget.classList.add('border-red-600')
      }
      this.confirmBillButton.disabled = true
    } else {
      this.accountBalanceNotEnoughMessage.classList.add('hidden')
      if (this.accountBalanceNotEnoughTarget) {
        this.accountBalanceNotEnoughTarget.classList.remove('border-red-600')
      }
      this.confirmBillButton.disabled = false
    }
  }

  get amountInput (): HTMLInputElement {
    return this.el.querySelector<HTMLInputElement>('#js-amount-input')!
  }

  get giftAmountInput (): HTMLInputElement {
    return this.el.querySelector<HTMLInputElement>('#js-gift-amount')!
  }

  get confirmBillButton (): HTMLButtonElement {
    return this.el.querySelector<HTMLButtonElement>('#js-confirm-bill-button')!
  }

  get accountBalanceNotEnoughMessage (): HTMLElement {
    return this.el.querySelector<HTMLElement>('#js-account-balance-not-enough')!
  }

  get accountBalanceNotEnoughTarget (): HTMLElement | null {
    return this.el.querySelector<HTMLElement>('#js-account-balance-not-enough-target')
  }
}
