import * as drawer from './drawer'
import * as oneOff from './one_off'
import * as flash from './flash'
import './back_link'
import './check_all'
import './close'
import './dialog'
import './disable_button'
import './elements'
import './print'
import './radio_switcher'
import './tooltip'
import './payment'

function load () {
  drawer.init()
  flash.init()
}

function beforeCache () {
  drawer.destroy()
  oneOff.destroy()
  flash.destroy()
}

document.addEventListener('turbo:load', load)
document.addEventListener('turbo:before-cache', beforeCache)
