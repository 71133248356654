export type Balance = {
  point: number;
  money: number;
  furusatoMoney: number;
}

export class BalanceCalculator {
  moneyBalance: number
  pointBalance: number
  furusatoMoneyBalance: number
  useFurusatoMoney: boolean

  constructor (
    moneyBalance: number,
    pointBalance: number,
    furusatoMoneyBalance: number,
    useFurusatoMoney: boolean
  ) {
    this.moneyBalance = moneyBalance
    this.pointBalance = pointBalance
    this.furusatoMoneyBalance = furusatoMoneyBalance
    this.useFurusatoMoney = useFurusatoMoney
  }

  pay (amount: number): Balance {
    const r1 = this.useFurusatoMoney ? Math.max(0, amount - this.furusatoMoneyBalance) : amount
    const r2 = Math.max(0, r1 - this.pointBalance)
    const furusatoMoney = this.useFurusatoMoney ? Math.max(0, this.furusatoMoneyBalance - amount) : this.furusatoMoneyBalance
    const point = Math.max(0, this.pointBalance - r1)
    const money = this.moneyBalance - r2
    return { point, money, furusatoMoney }
  }

  topup (amount: number): Balance {
    return {
      point: this.pointBalance,
      money: this.moneyBalance + amount,
      furusatoMoney: this.furusatoMoneyBalance
    }
  }
}
