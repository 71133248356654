import { BalanceCalculator } from 'libs/balance_calculator'

type CalculationType = 'pay' | 'topup'

export function init () {
  const el = document.querySelector<HTMLElement>('.js-balance-calculator')
  if (!el) { return }

  const money = Number(el.dataset.moneyBalance)
  const point = Number(el.dataset.pointBalance)
  const furusatoMoney = Number(el.dataset.furusatoMoneyBalance)
  const useFurusatoMoney = el.dataset.useFurusatoMoney === '1'
  const calcType = el.dataset.calculationType as CalculationType
  const calculator = new BalanceCalculator(money, point, furusatoMoney, useFurusatoMoney)
  bindCalculatorEvents(el, calculator, calcType)
  // NOTE: 他の initializers の初期化が終わったあとで実行するよう setTimeout している。確実にイベントを拾ってもらうため
  setTimeout(() => {
    recalculate(el, calculator, calcType)
  })
}

function bindCalculatorEvents (el: HTMLElement, calculator: BalanceCalculator, calcType: CalculationType) {
  for (const input of document.querySelectorAll<HTMLInputElement>('.js-balance-calculator-input')) {
    // 数値を入力した時点で反映（input）と、割合ボタンを押した時（change）の両方が必要
    input.addEventListener('input', () => recalculate(el, calculator, calcType))
    input.addEventListener('change', () => recalculate(el, calculator, calcType))
  }
}

function recalculate (el: HTMLElement, calculator: BalanceCalculator, calcType: CalculationType) {
  const inputs = Array.from(document.querySelectorAll<HTMLInputElement>('.js-balance-calculator-input'))
  const amount = inputs.reduce((n, el) => n + Number(el.value), 0)
  const balance = (() => {
    switch (calcType) {
      case 'pay':
        return calculator.pay(amount)
      case 'topup':
        return calculator.topup(amount)
    }
  })()

  showCalculationResult('.js-balance-calculator-money-result', balance.money)
  showCalculationResult('.js-balance-calculator-point-result', balance.point)
  showCalculationResult('.js-balance-calculator-furusato-money-result', balance.furusatoMoney)

  el.dispatchEvent(new CustomEvent('balance:calculated', { detail: balance }))
}

function showCalculationResult (selector: string, value: number) {
  const el = document.querySelector(selector)
  if (el) {
    el.textContent = value.toLocaleString()
  }
}
